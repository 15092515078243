const isPhoneNumber = (context, phoneNumber) => {
  const phoneRegExp = /^[6-9]\d{9}$/;
  const allSameDigits = context.phoneInputEl.value
    .split('')
    .every((digit) => digit === phoneNumber[0]);
  return (
    phoneNumber.length === 10 && phoneRegExp.test(phoneNumber) && !allSameDigits
  );
};

const validatePhoneNumber = (context) => {
  const phoneNumber = context.phoneInputEl.value.trim();
  if (isPhoneNumber(context, phoneNumber)) {
    context.phoneInputEl.style.borderColor = '';
    return true;
  }
  if (phoneNumber.length >= 10) {
    context.errorBoxEl.textContent = 'Please Enter Valid Mobile Number';
    context.errorBoxEl.style.display = 'block';
  }
  context.phoneInputEl.style.borderColor = '#E50914';
  return false;
};

const onGetStartedClicked = async (context, event) => {
  event.preventDefault();
  if (context.checkBoxEl.checked) {
    if (validatePhoneNumber(context) && (await validateTid(context))) {
      context.form.submit();
    }
  } else {
    if (validatePhoneNumber(context)) {
      context.form.submit();
    }
  }
};

const onPhoneInputHandler = (context) => {
  if (validatePhoneNumber(context)) {
    context.errorBoxEl.style.display = 'none';
  } else {
    context.errorBoxEl.style.display = 'block';
  }
  if (context.phoneInputEl.value.length == 0) {
    context.phoneInputEl.style.borderColor = '';
    context.errorBoxEl.style.display = 'none';
  }
};

const onPhoneInputKeyPress = (context, event) => {
  const char = String.fromCharCode(event.charCode);
  if (event.charCode === 13) {
    event.preventDefault();
    onGetStartedClicked(context, event);
  }
  if (!/\d/.test(char) || context.phoneInputEl.value.length === 10) {
    event.preventDefault();
  }
};

const onTIDPaste = (context, event) => {
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData('Text');
  if (!/\d/.test(pastedData) || pastedData.length > 7) {
    event.preventDefault();
    handleApplyBtnClick(context, event);
  }
  if (pastedData.length === 7) {
    handleApplyBtnDisplay(context, event);
  }
};

const handleApplyBtnClick = async (context, event) => {
  event.preventDefault();
  const isValid = await validateTid(context);
  if (isValid) {
    context.tidApplyButtonEl.style.display = 'none';
    context.tidApplyButtonEl.textContent = 'Applied !';
  }
};

function initializeGetStartedFormSticky(form) {
  const context = {
    form: form,
    phoneInputEl: form.querySelector("input[name='phone']"),
    submitButtonEl: form.querySelector("button[type='submit']"),
    errorBoxEl: form.closest('.custom__search').querySelector('.error-box'),
    tidErrorMsgEl: form.querySelector('.warning-msg > p'),
  };
}

function init() {
  const forms = document.querySelectorAll("form[id^='onboardingFormSticky']");
  forms.forEach(initializeGetStartedFormSticky);
}

document.addEventListener('DOMContentLoaded', init());
